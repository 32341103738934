import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query productQuery {
      img0: file(relativePath: { eq: "book_cover.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.Products" description="seo.desc.Products" />
      <Container className="product-container">
        <Row className="pt-4">
          <Col xs={12}>
            <h3>{tt("pages.Products.title1")}</h3>
          </Col>
        </Row>

        {locale !== "en" && (
          <Row className="pt-4 pb-4 product-row">
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <Row>
                <Col>
                  <Img
                    className="rounded mx-auto d-block pb-3 image"
                    fluid={data.img0.childImageSharp.fluid}
                  />
                </Col>
              </Row>
              <Row>
                <div className="bookdesc">
                  <p>{tt("pages.Products.bookdesc")}</p>
                  <p>{tt("pages.Products.author")}</p>
                </div>
              </Row>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="description">
              <p className="">{tt("pages.Products.desc1")}</p>
            </Col>
          </Row>
        )}

        {locale === "en" && (
          <Row className="my-4">
            <Img
              className="rounded mx-auto d-block pb-3 image"
              fluid={data.img0.childImageSharp.fluid}
            />
          </Row>
        )}

        <Row className="pt-4 pb-4">
          <Col xs={12} className="emaildesc">
            <p>{tt("pages.Products.desc2")}</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ThePage
